* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    /* font-family: 'Cinzel', serif; */
    font-family: "Graphik",Helvetica Neue,Helvetica,Arial,sans-serif;
    /* font-family: "AptHeadlineSemiBold","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif; */
}

#navcontrol a {
    font-size: 2rem;
    font-weight: bold;
    color:#fff;
    transition: all 0.8s ease;
    transform: translateX(10px);
}
#navcontrol li{
    transition: all 0.8s ease;

} 
#navcontrol li:hover{ 
   transform: translateX(50px);
}

#navcontrol a:hover .navcontrol {
    background-color: red;
}



.separation {
    width: 12%;
    margin: 0 auto;
}

.MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.push--bottom {
    margin-bottom: 20px !important;
}

.push--top {
    margin-top: 20px !important;
}

@media only screen and (max-width: 768px) {
    .header_box {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1f2732;
        position: relative;
    }

    .header_box h1 {
        font-size: 2rem;
        font-weight: 300;
        /* font-family: 'Poppins', sans-serif; */
    }

    .header_box a {
        list-style: none;
        text-decoration: none;
        color: #fff;
        font-size: 1rem;
        font-weight: 300;
        /* font-family: 'Poppins', sans-serif; */
    }

    .nav_icon {
        width: 700px;
        display: flex;
        justify-content: space-around;
    }

    .header_box h1 {
        font-size: 1rem;
    }

    .header_box .nav_icon {
        display: flex;
        flex-direction: column;
        z-index: 99999;
    }

    .menu_btn {
        display: block !important;
    }

    .nav_icon {
        position: absolute;
        top: 80px;
        left: -784px;
        width: 100%;
        height: 88vh;
        background-color: rgba(0, 0, 0, 0.78);
        text-align: center;
        transition: all 0.7s ease;
    }

    /* @media only screen and (max-width: 768px){ */
    .mobile {
        position: absolute;
        top: 60px;
        left: 0px;
        width: 100% !important;
        height: 93vh;
        /* padding: 130px 60px; */
        /* background-color:#000; */
        padding: 90px 53px 160px 53px;
        background-image: url('./images/tudorarchshapes/navbgtudor.jpg');
        /* background-image: url(../component/images/navmini.jpg); */
        background-size: cover;
        z-index: 9999;
        /* } */
    }

    .mobile a {
        font-weight: 600;
        /* font-family: Georgia, 'Times New Roman', Times, serif; */
        color: #fff !important;
    }

    .nav_icon a {
        color: #fff;
    }
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */

}

.header_box {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #fffce6; */
    /* position: fixed; */
    width: 100%;
    /* top: 0;
    left: 0; */
    /* z-index: 3; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
}

li {
    list-style: none;
}

.header_box h1 {
    font-size: 1.6rem;
    font-weight: 300;
    color: #F3742A;

}

.header_box a {
    list-style: none;
    text-decoration: none;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
}

.header-content h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: 2px;
}

.header-content h2 {
    color: #eee;
    margin: 10px 0 0 0;
    font-size: 1.5rem;
    /* font-family: "Raleway", sans-serif; */
}

.nav_icon {
    width: 500px;
    display: flex;
    justify-content: space-around;
}

.menu_btn {
    display: none;
}


.logo {
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    /* left: 15px; */
    position: relative;
    z-index: 999999;
}

.logobottom {
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    /* left: 15px; */
    position: relative;
    /* z-index: 999999; */
}

.video-play-btn {
    width: 90px;
    height: 90px;
    border: 2px solid #F3742A;
    position: absolute;
    content: "";
    border-radius: 50%;

}


/* .small,
small {
    font-size: 80%;
    font-weight: 400;
} */

a {
    color: #ffffff;
    text-decoration: none;
}

.bg {
    /* height: 100vh; */
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image:linear-gradient(180deg, rgba(0, 0,0, 0.3), rgba(2, 0, 0, .5)), url(https://bootstrapmade.com/demo/templates/Mentor/assets/img/hero-bg.jpg) ; */
    /* background-image:linear-gradient(180deg, rgba(0, 0,0, 0.3), rgba(2, 0, 0, .5)), url(/src/images/bannernew.png) ; */

}

.margin {
    margin-top: 140px !important;
}

.video-play-btn span {
    font-size: 20px;
    position: absolute;
    top: 36%;
    left: 43%;
    color: #fff;
}

.hamburger {
    display: none;
}

@media only screen and (max-width: 600px) {
    .navbarlist {
        display: none;
    }

    .enrollbutton {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .bg-1 {
        width: 83%;
        height: 78%;
        right: 13%;
        top: -31px;
        z-index: -1;
        background: rgba(15, 183, 141, 0.2);
        position: absolute;
        border-radius: 12px;
    }

}




/* Home Banner---- */
.banner {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../component/images/Ikat_1800x.jpg);
    background-size: cover;
    background-position: center; */
    /* height: 90vh; */
    /* margin-top: 60px; */
    overflow: hidden;
    /* position: relative; */
    /* border-radius: 100% 0% 100% 0% / 0% 84% 16% 100%; */
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;

}

.text_box_banner {
    background-color: rgba(255, 255, 255, 0.6);

}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: gray;
    background-color: #fff;
    /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e); */
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.box {
    position: relative;
    bottom: 80px
}


/* Why Choose Aj Wedding--- */
.why_choose {
    /* background-image: url(../component/images/waves-bg-track-y.webp); */
    background-size: cover;
    background-position: center;
}

.why_choose h2 {
    color: #51505d;
}

.why_choose .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.why_choose p {
    color: #51505d;
}

.why_choose .card {
    min-height: 313px;
}


/* Help ------ */
/* .stage {
    background-image: url(../component/images/gelp_bg_2.jpg);
    background-size: cover;
    background-position: center;
} */

.help_img {
    background-image: url(https://imgs.bharatmatrimony.com/bmimgs/dhoni-bannertxtimg.webp);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 400px;
}

.stage p,
.stage ul li {
    font-size: 1.2rem;
}

/* Achivment */
.achivement {
    /* background-image:url(../component/images/waves-bg-track-y.webp); */
    background-color: #c0cdd3;
    background-size: cover;
}

.achivement i {
    color: #e72e77;
    font-size: 3rem;
}

.achivement span {
    font-size: 1.2rem;
}



/* Browse----- */
/* .browse .browse_bg {
    background-image: url(../component/images/browse.jpg);
    background-repeat: no-repeat;
    height: 45vh;

} */

.hommeform .container {
    background-color: #ffd2cf;
}



/* Footer */

.footer {
    /* background: #032D60; */
    /* background-image: url(../component/images/MAHAJAN\ \(1\).jpg); */
    /* background-position: center;
    background-size: cover; */
    /* background-color: #e72e77; */
    font-size: 14px;
    color: #ffffff;
    padding: 23px 0;
}

.footer p {
    color: #51505d;
}





@media (max-width:990px) {
    .form_img img {
        width: 100% !important;
    }

    .hommeform {
        height: auto !important;
    }

    .browse .browse_bg {
        background-image: url(../component/images/minibrowse.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 55vh;

    }

    .browse .browse_bg a {
        position: relative;
        top: 255px !important;
        left: 170px !important;

    }

    .sideimg {
        /* background-image: url('./images/imgsidemini.png'); */
        background-size: cover;

    }
}





/* ------------------Photos--------------- */
.marriage {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../component/images/cot2.jpg); */
    background-size: cover;
    height: 80vh;
    margin-top: 60px;
    background-position: center;
}

.photos_container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0%;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
}

.gallery img {
    width: 100%;
    border-radius: 50%;
    /* filter: grayscale(100%); */
    transition: all ease 0.5s;
}

.gallery img:hover {
    filter: none;
}



/* DropDown Css----- */

/* Navbar DropDown==== */

.navbar-nav .service-submenu a {
    color: #fff !important;
    font-size: 0.8em;
    padding: 0.3em 0 0.3em 0em;
    text-align: start;
    text-decoration: none;
}

.header_box .nav_icon li .service-submenu a:hover {
    color: #fff !important;

}

.header_box .nav_icon li .service-submenu a {
    color: #fff !important;

}

.service-submenu p {
    color: #fff;
}

.service-submenu h6 {
    color: #ffff;
    border-bottom: 2px solid #fff;
    padding: 0.5em 0;
    /* margin: 0 0.5em; */
    text-transform: uppercase;
    text-align: left;
    font-size: 1.2em;
}

.service-submenu {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    padding: 15px;
}

.service-submenu p {
    font-style: italic;
}

.service-menu {
    position: absolute;
    height: 0vh;
    top: 160px;
    left: 20%;
    transform: translateX(-20%);
    /* background: linear-gradient(rgba(2, 2, 2, 0), rgba(0, 0, 0, 0)), url(../component//images/); */

    background-color: #010100;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0.7em;
    z-index: 1;
    /* justify-content: center; */
    transition: all 0.7s cubic-bezier(1, 0, 0, 1);
    visibility: hidden;
    opacity: 0;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.315);
}

.header_box .nav_icon li:hover .service-menu {
    visibility: visible !important;
    opacity: 1 !important;
    height: 70vh !important;
}

.ven_bg {
    transition: all 0.5s ease;
    position: relative;
}

.ven_bg:hover {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    border-radius: 10px;
}

.topbar {
    position: absolute;
    top: 0;
    left: 0;
}

.topbar .badge {
    clip-path: polygon(100% 0, 86% 50%, 100% 100%, 0 100%, 0 0);

}

/* Quality------ */
.quaitybg1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://drive.apt.london/2021/09/_cache/62ed4640f516e1b2de1a1b87f3e73d3c_f8f80900c5bcb1acfc42788b61ee8936.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
}

.quaitybg3 {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/kitchen1.jpg'); */
    background-size: cover;
    background-position: center;
    position: relative;
    height: 80vh;
    overflow: hidden;
}
.quaitybg9 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/kitchen1.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 80vh;
    overflow: hidden;
}

.quaitybg4 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/img212.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 80vh;
    overflow: hidden;
}

.innerqualityup {
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.quaitybg3:hover .innerqualityup {
    top: -300px;

}

.quaitybg3:hover .innerquality3 {
    left: 0;

}

.quaitybg3:hover .innerqualityup3 {
    top: -300px;

}

.quaitybg9:hover .innerquality9 {
    left: 0;

}

.quaitybg9:hover .innerqualityup9 {
    top: -300px;

}

.quaitybg4:hover .innerquality4 {
    left: 0;

}

.quaitybg4:hover .innerqualityup4 {
    top: -300px;

}

.innerqualityup4 {
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.innerqualityup3 {
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.innerqualityup9 {
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.innerquality9 {
    position: absolute;
    top: 0px;
    left: -100%;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    /* border: 2px solid #fff; */
    padding: 20px;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
}
.innerquality3 {
    position: absolute;
    top: 0px;
    left: -100%;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    /* border: 2px solid #fff; */
    padding: 20px;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
}

.innerquality4 {
    position: absolute;
    top: 0px;
    left: -100%;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    /* border: 2px solid #fff; */
    padding: 20px;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
}

/* .innerquality3::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -100%;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 1);
    padding: 20px;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
} */
.innerquality::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* border: 2px solid #fff; */
    padding: 20px;
    height: 100%;
    width: 100%;
    outline: 2px solid #fff;
    outline-offset: -25px
}

.quaitybg2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://drive.apt.london/2021/05/_cache/af34a632f745ea3956e4967413ecd2e7_66439e81dfd71a9f9f0c83b827dece6d.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;

}

.innerquality2 {
    position: relative;
    width: 100%;
    height: 100%;
}

.innerquality2::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* border: 2px solid #fff; */
    padding: 20px;
    height: 100%;
    width: 100%;
    outline: 2px solid #fff;
    outline-offset: -25px
}

.qualitytextbox2 h2,
.qualitytextbox2 h4,
.qualitytextbox2 p {
    color: #b06033;

}

/* Quality------ */

/* category_box------ */
.category_box1 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/img179.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box2 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/kitchen2.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box3 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/interior3.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box4 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/img256.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box5 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/img209.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box6 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/interior8.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box7 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/pop1.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box8 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/poly1.jpg');
    background-size: cover;
    height: 40vh;
    background-position: center;

}

.category_box9 {
    position: relative;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('./images/cotl1.jpg'); */
    background-size: cover;
    height: 40vh;
    background-position: center;

}

/* category_box------ */

/* Lower Banner----- */
.lower_banner_box {
    background-image: url('./images//poster1.png');
    background-size: cover;
}

@media (max-width:990px) {
    .lower_banner_box {
        background-image: url('./images/Quality & Affordability.png');
        background-size: cover;
        height: 84vh !important;
        background-position: center;
    }
}

/* Lower Banner----- */

/* Story Box---- */
.story_box {
    background-image: url('./images/img263.jpg');
    background-attachment: fixed;
    background-size: cover;

}

/* Story Box---- */



/* Our Achievement---- */
/* Counter=========== */

.counts {
    background-color: #fefbe5;
}

.counts span {
    font-size: 3rem;
    color: #fff;
    font-family: 300;

}

.counts p {
    font-size: 1rem;
    color: #222;
    font-family: 300;

}

/* Counter=========== */

/* Our Achievement---- */


/* Reviews----/ */

/* Reviews----/ */

/* Form---- */
.cta {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url('./images/img175.jpg');
    /* background-size: repeat; */
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    /* RGB(175, 96, 51) */
}

/* Form---- */






/* ABout Banner-------- */
.about_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url('./images/aboutbanner.jpg');
    background-size: cover;

}

/* ABout Banner-------- */

/* product */
.product_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url('./images/aboutbanner.jpg');
    background-size: cover;
    height: 100vh;
    /* margin-top:160px; */

}

@media(max-width:990px) {
    .product_banner {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url('./images/aboutbanner.jpg');
        background-size: cover;
        height: 80vh;
        margin-top: 60px;

    }

    .project {
        margin-top: 90px !important;
    }

    .service_banner h1 {
        font-size: 3rem !important;
    }
}

/* product */



/* ABouts Us----- */
.right_about {
    /* background-image: url('./images/interior1.jpg'); */
    background-image: url('./images/img297.jpg');
    background-size: cover;
    height: 90vh;
    background-position: center;
    position: relative;
}

.left_about {
    position: relative;

}

.left_about::before {
    content: '';
    position: absolute;
    bottom: -76px;
    left: -68px;
    height: 64% !important;
    width: 100%;
    background-image: url('./images/img256.jpg');
    background-size: cover;
    transform: rotate(0deg);

    background-position: center;

}

.about_bottom_banner {
    background-image: url('./images/about_design.jpg');
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;

}

@media (max-width:990px) {
    .about_bottom_banner {
        background-image: url('./images/miniaboutdesign.jpg');
        background-size: cover;

    }
}

.sideimg {
    background-image: url('./images/interior2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}

/* ABouts Us----- */


/* Contact------- */

/* .contactside{
    background-image:url(../component/images/contact_side.jpg);
    background-size: cover;
    height: 100%;
}
@media(max-width:990px)
{
    .contactside {
       
        height: 50vh !important;
    }
} */
/* Contact------- */


/* Cta1--- */
.cta1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://wordsleads.com/images/contactbg-2.png');
    background-attachment: fixed;
    background-size: cover;
}

/* Cta1--- */


/* Whatsapp---- */
#whatsapp {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

#whatsapp a {
    background-color: #3c9f5b;
    color: #fff;
    border-radius: 50%;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 30px !important;
    justify-content: center;
    margin: 0;
    padding: 1.6rem;
    width: 30px !important;
}

#whatsapp i {
    fill: #fff;
    height: auto;
    width: auto;
    font-size: 2rem;
}

/* Whatsapp=== */
/* Whatsapp---- */


/* Services---- */
.service_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://drive.apt.london/2022/03/_cache/f06bd2e061017c88bf888a757c0a781e_d53a25115e96ee1af1d722f329722dab.jpg');
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.about_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/aboutbanner.jpg');
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.contact_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/img104.jpg');
    height: 100vh;
    background-size: cover;
    background-position: center;
}

/* Services---- */


@keyframes myAnimation {
  0%{
    opacity: 0;
  }
    50% {
        opacity: 1;
        /* color: yellow; */
        transition: all 0s linear 300ms, opacity 300ms;
    }

    
    
}
/* 
.preloader span{
    animation: myAnimation 1s linear;
    opacity: 1;

    
} */
.preloader img{ 
    opacity: 0;
    animation:fade 6s;
    transform:scale(1)

}
@keyframes fade {
    0%{
        opacity:0;
        
    }
    
    100%{
        opacity:1;
        /* transform:scale(1.2) */
    }
}

.preloader{
    top:-700px;
    animation:gototop 0.6s 6s ease;
}


@keyframes gototop {

    0%{
        top:0;
    }
    100%{
        top:-700px;
    }

}



.preloader span{
    animation: myAnimation 5.5s linear;
    opacity: 1;
    color: #fff;
    /* -webkit-text-stroke: 2px #fff; */
}


.preloader span:nth-child(1) {
    
    /* animation: myAnimation 0.9s linear infinite; */
       animation-delay: 0.0s;
       font-size: 8rem;
}

.preloader span:nth-child(2) {
    
    /* animation: myAnimation 0.10s linear infinite; */
       animation-delay: 0.5s;
       font-size: 8rem;

}

.preloader span:nth-child(3) {
    
    /* animation: myAnimation 0.11s linear infinite; */
    animation-delay: 1s;
    font-size: 8rem;
    
}

.preloader span:nth-child(4) {
    
    /* animation: myAnimation 0.12s linear infinite; */
    animation-delay: 1.5s;
    font-size: 8rem;
    
}

.preloader span:nth-child(5) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 2s;
    font-size: 8rem;
    
}
.preloader span:nth-child(6) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 2.5s;
    font-size: 8rem;
    
}
.preloader span:nth-child(7) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 3s;
    font-size: 8rem;
    
}
.preloader span:nth-child(8) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 3.5s;
    font-size: 8rem;
    
}
.preloader span:nth-child(9) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 4s;
    font-size: 8rem;
    
}
.preloader span:nth-child(10) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 4.5s;
    font-size: 8rem;
    
}
.preloader span:nth-child(11) {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 5s;
    font-size: 8rem;
    
}
.preloader span {
    
    /* animation: myAnimation 0.10s linear infinite; */
    animation-delay: 5.5s;
    /* opacity: 1;
    color: red;
     */
}




/* Founder----- */
.founder_left {
    background-image: url('./images/founder1.jpg');
    background-position: 50%;
    background-size: cover;
    height: 60vh;
}
.right_text {
    align-items: center;
    background-color: #e0d957;
    color: #fff;
    display: flex;
    height: 60vh;
    flex-direction: column;
    justify-content: center;
}
.founder_left1 {
    background-image: url('./images/founder1.jpg');
    background-position: 50%;
    background-size: cover;
    height: 60vh;
}
.right_text1 {
    align-items: center;
    background-color: #e0d957;
    color: #fff;
    display: flex;
    height: 60vh;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 990px){
    .right_text {
        
        height: 100%;
       
    }
    .founder_left {
        
        height: 60vh;
    }
    .right_text1 {
        
        height: 100%;
       
    }
    .founder_left1 {
        
        height: 60vh;
    }

}
/* Founder----- */

/* team----/ */
/* team------ */

.boxnew {
	border-radius: 150px;
	background:#fff;
	position:relative;
	overflow: hidden;
	text-align:center;
}
.boxnew:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 0px;
    height: 100%;
    border-radius: 150px;
    box-shadow: inset 0 0 25px rgba(0,0,0,0.30);
    transition: all 0.3s ease;
    background-image: linear-gradient(to right, #e0d957 0%, #2222 100%);
}
.boxnew:hover:before {
    width: 100%;
}
.boxnew:hover .image-wrapper {
	padding:0;
}
.boxnew:hover .box-desc {
	color:#fff;
}
.boxnew:hover .social li a {
	background:#fff;
	background-image: none;
	color:#000;
}
.boxnew:hover .social li a:hover {
	background:#1d1d1d;
	color:#fff;
}
.image-wrapper {
    position: relative;
	max-width: 210px;
    max-height: 210px;
	margin:0 auto;
    overflow: hidden;
    border-radius: 50%;
    padding: 15px;
    transition: all 0.5s ease;
    box-shadow: inset 0px 0px 20px rgba(0,0,0,0.20);
}
.image-wrapper img {
    border-radius: 50%;
    transition: all 500ms ease;
}
.box-desc {
	position:relative;
}
ul.social {
	padding:0;
}
ul.social li {
	display:inline-block;
	list-style-type:none;
}
ul.social li a {
	position:relative;
	width: 36px;
    height: 36px;
	background-image: linear-gradient(to right, #e0d957 0%, #222 100%);
	display:inline-block;
	line-height:36px;
	border-radius:50%;
	color:#fff;
	transition: all .5s ease;
}

/* team------ */

/* team----/ */



















/* ____Tech Interio--- CSS */

.mini_slide{
    /* transform:matrix(0.4, 0, 0, 0.4, 0, 0); */
    transition:all 0.4s ease;
}
#carouselExampleInterval{
    transition:all 0.4s ease;

}

@media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
        display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
    transform: translateX(0);
}

.carousel-inner {
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.inner_text_div h2{
    font-size:1.5rem;
}
.breadcrumb li{
    font-size:0.6rem;
}
.carousel-control-prev{

        height: 30px;
        width: 30px;
        background: red;
        color: red;
        top: 50%;
        transform: translateY(-50%);
}
.carousel-control-next{

        height: 30px;
        width: 30px;
        background: red;
        color: red;
        top: 50%;
        transform: translateY(-50%);
}
.line {
    height: 0%;
    width: 2px;
    position: absolute;
    top: 0;
    left: -10px;
    opacity: .25;
    background: #fff;
}

.line_content{ 
    opacity: 0.7;
    color:#fff;
    font-size:11px;

}



/* Products-- */
.products_list{
    height: 50vh;
}

.lower_info{
    background-color:#1B1B1E;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    transition: all 0.5s ease;
}
.lower_info h5{
    color:#fff;
}


.img_box img{ 
    z-index: 2;
    position: relative;
    transition: all 4s ease;
}

.img_box:hover .lower_info  {
    bottom: -110px;
}


/* Products-- */


/* mob slider---- */
.owl-stage{
    display: flex;
}
.owl-item{
    width:500px !important;
}

.m-slide{ 
    object-fit:cover;
    height:100vh;
    transition: all 0.6s ease;


}
.changeclass{
    object-fit:cover;
    height:60vh !important;
    transition: all 0.6s ease;

}
.owl-dots{
    display:none;
}
/* mob slider---- */


/* ____Tech Interio--- CSS */